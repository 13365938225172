var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"ft-pr","size":"xl","title":"Ürün açıklamaları","ok-title":"Kaydet"},on:{"ok":_vm.saveFeatured}},_vm._l((_vm.featuredProducts),function(item,i){return _c('b-row',{key:i,staticClass:"mt-1 border-bottom d-inline-flex align-items-center w-100 py-1"},[_c('b-col',{attrs:{"md":"3"}},[_c('span',[_vm._v(_vm._s(item.name))])]),_c('b-col',{attrs:{"md":"3"}},[_c('span',[_vm._v(_vm._s(item.category_name))])]),_c('b-col',{attrs:{"md":"3"}},[_c('b-img',{attrs:{"rounded":"","src":item.photo.icon_url}})],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-input',{attrs:{"placeholder":"Ürün açıklaması","size":"sm"},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}})],1)],1)}),1),_c('vue-good-table',{ref:"tablePr",attrs:{"id":"tablePr","columns":_vm.columns,"rows":_vm.rows,"select-options":{
      enabled: true,
      selectOnCheckboxOnly: true,
      clearSelectionText: 'Seçili olanları temizle',
    },"search-options":{
      enabled: true,
      externalQuery: _vm.searchTerm },"pagination-options":{
      enabled: true,
      perPage:_vm.pageLength
    }},on:{"on-selected-rows-change":_vm.selectionChanged},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'photoUrl')?_c('b-img',{attrs:{"src":props.row.photo.icon_url}}):_vm._e()]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" Showing 1 to ")]),_c('b-form-input',{staticClass:"mx-1",attrs:{"type":"number"},on:{"input":function (value){ return props.perPageChanged({currentPerPage:value}); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap "},[_vm._v(" of "+_vm._s(props.total)+" entries ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value){ return props.pageChanged({currentPage:value}); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}])},[_c('div',{attrs:{"slot":"selected-row-actions"},slot:"selected-row-actions"},[_c('BButton',{attrs:{"variant":"info","size":"sm","disabled":_vm.featuredProducts.length !== 4},on:{"click":_vm.openNext}},[_vm._v(" Güncelle ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }