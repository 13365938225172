<template>
  <div>
    <b-modal
      id="ft-pr"
      size="xl"
      title="Ürün açıklamaları"
      ok-title="Kaydet"
      @ok="saveFeatured"
    >
      <b-row
        v-for="(item, i) in featuredProducts"
        :key="i"
        class="mt-1 border-bottom d-inline-flex align-items-center w-100 py-1"
      >
        <b-col md="3">
          <span>{{ item.name }}</span>
        </b-col>
        <b-col md="3">
          <span>{{ item.category_name }}</span>
        </b-col>
        <b-col md="3">
          <b-img
            rounded
            :src="item.photo.icon_url"
          />
        </b-col>
        <b-col md="3">
          <b-form-input
            v-model="item.description"
            placeholder="Ürün açıklaması"
            size="sm"
          />
        </b-col>
      </b-row>
    </b-modal>
    <vue-good-table
      id="tablePr"
      ref="tablePr"
      :columns="columns"
      :rows="rows"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true,
        clearSelectionText: 'Seçili olanları temizle',
      }"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      @on-selected-rows-change="selectionChanged"
    >
      <div slot="selected-row-actions">
        <BButton
          variant="info"
          size="sm"
          :disabled="featuredProducts.length !== 4"
          @click="openNext"
        >
          Güncelle
        </BButton>
      </div>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <b-img
          v-if="props.column.field === 'photoUrl'"
          :src="props.row.photo.icon_url"
        /></template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-input
              v-model="pageLength"
              class="mx-1"
              type="number"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>
<script>
import { VueGoodTable } from 'vue-good-table'
import {
  BPagination, BFormInput, BButton, BImg, BModal, BCol, BRow,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BPagination, BFormInput, BButton, VueGoodTable, BImg, BModal, BCol, BRow,
  },
  data() {
    return {
      rows: [],
      featuredProducts: [],
      pageLength: 15,
      searchTerm: '',
      columns: [
        {
          label: 'Ürün İsmi',
          field: 'name',
          width: '150px',
          hidden: false,
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Kategori',
          field: 'category_name',
          width: '150px',
          hidden: false,
          filterOptions: {
            enabled: true,
          },
        },
        {
          label: 'Ürün resmi',
          field: 'photoUrl',
          width: '150px',
          hidden: false,
          filterOptions: {
            enabled: true,
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters('product', ['getProductsData']),
  },
  created() {
    this.filterProducts()
  },
  methods: {
    ...mapActions('product', ['getProducts']),
    filterProducts(flt = null) {
      this.getProducts(flt).then(() => {
        this.rows = this.getProductsData
      })
    },
    selectionChanged(e) {
      this.featuredProducts = e.selectedRows
    },
    openNext() {
      // console.log(this.featuredProducts)
      this.$bvModal.show('ft-pr')
    },
    saveFeatured() {
      this.$http.post('product/featured', this.featuredProducts).then(res => {
        if (res.data) {
          this.featuredProducts = []
          this.$refs.tablePr.unselectAllInternal()
        }
      })
    },
  },

}
</script>
